/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.slider').slick({
          lazyLoad: 'progressive',
          autoplay: true,
          dots: true,
          autoplaySpeed: 6000,
          speed: 1000,
          fade: true,
          cssEase: 'linear',
          infinite: true,
          pauseOnHover: false,
          waitForAnimate: false
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.slider').show();

        // Run code for each element
         // $('.embed-responsive').each( function (){

         // // Get ar
         // var ar = $(this).attr('data-aspectratio');

         // // Check for existance and set padding-bottom
         // if (typeof ar !== typeof undefined && ar !== false ) {
         //   $(this).css('padding-bottom', (1/ar)*100+'%');
         // }

         // });



        var $grid = $('.gallery').imagesLoaded( function() {
          // init Masonry after all images have loaded
          $grid.masonry({
            // options...
            itemSelector: '.cols',
            columnWidth: '.cols'
          });
        });


        $('.photoswipe').each( function() {
          var $pic     = $(this),
            getItems = function() {
              var items = [];
              $pic.find('a').each(function() {
                var $href   = $(this).attr('href'),
                  $title  = $(this).find('img').attr('alt'),
                  $size   = $(this).data('size').split('x'),
                  $width  = $size[0],
                  $height = $size[1];

                var item = {
                  src : $href,
                  w   : $width,
                  h   : $height,
                  title : $title
                };

                items.push(item);
              });
              return items;
            };

          var items = getItems();

          var $pswp = $('.pswp')[0];
          $pic.on('click', 'figure', function(event) {
              event.preventDefault();

              var $index = $('a', this).data('index');
              console.log($index);
              var options = {
                  index: $index,
                  bgOpacity: 0.9,
                  showHideOpacity: true
              };

              // Initialize PhotoSwipe
              var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
              lightBox.init();
          });

        });

        $('.posts .card').matchHeight({ byRow: true });

        $('.videolist .card').matchHeight({ byRow: true });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'attachment': {
      init: function() {
        // JavaScript to be fired on the about us page


        var $grid = $('.gallerysidebar').imagesLoaded( function() {
          // init Masonry after all images have loaded
          $grid.masonry({
            // options...
            itemSelector: '.cols',
            columnWidth: '.cols'
          });
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

/* Script for asynchronus load of sharing buttons */

 (function(w, d, s) {

   function go(){
    var js, fjs = d.getElementsByTagName(s)[0], load = function(url, id) {
    if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.src = url; js.id = id;
      fjs.parentNode.insertBefore(js, fjs);
    };
        //Facebook
    load('//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.12&appId=1379074455440593&autoLogAppEvents=1', 'facebook-jssdk');
        //Google+
    load('https://apis.google.com/js/plusone.js', 'gplus1js');
        //Twitter
    load('//platform.twitter.com/widgets.js', 'tweetjs');
        //LinedIN
    // load('//platform.linkedin.com/in.js', 'lnkdjs');
        //Pinterest
    load('//assets.pinterest.com/js/pinit.js', 'pinitjs');
    }
  if (w.addEventListener) { w.addEventListener("load", go, false); }
    else if (w.attachEvent) { w.attachEvent("onload",go); }

}(window, document, 'script'));
